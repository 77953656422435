/* Icons */

button {
    svg {
        width: 16px;
        // margin-right: 12px;
        // color: theme-color(secondary);
        transition: all .3s ease-in-out;
        pointer-events: none;
    }
    &:hover {
        box-shadow: 0 0 10px 0 darken($card-bg, 10%);
        z-index: 9;
        i,
        svg {
            transform: scale(1.3);
            // color: theme-color(primary);
        }
    }
}

.icons-list {
    border-left: 1px solid $border-color;
    border-top: 1px solid $border-color;
    >div {
        border-bottom: 1px solid $border-color;
        border-right: 1px solid $border-color;
        background: $content-bg;
        @include display-flex;
        @include align-items(center);
        padding: 15px 20px;
        font-weight: 400;
        font-size: $default-font-size;
        transition: all .3s ease-in-out;
        i {
            display: inline-block;
            font-size: 20px;
            text-align: left;
            margin-right: 12px;
            color: theme-color(secondary);
            transition: all .3s ease-in-out;
        }
        svg {
            width: 20px;
            margin-right: 12px;
            color: theme-color(secondary);
            transition: all .3s ease-in-out;
        }
        &:hover {
            box-shadow: 0 0 10px 0 darken($card-bg, 10%);
            z-index: 9;
            i,
            svg {
                transform: scale(1.3);
                color: theme-color(primary);
            }
        }
    }
}

.icon-in-bg {
    width: 55px;
    height: 55px;
    text-align: center;
    @include display-flex();
    @include align-items(center);
    @include justify-content(center);
}

.rtl {
    .icons-list {
        >div {
            svg,
            i {
                margin-right: 0;
                margin-left: 12px;
            }
        }
    }
}
