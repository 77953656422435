.auth-page {
  .auth-left-wrapper {
    width: 100%;
    height: 100%;
    //background-image: url(../../images/Free-Mobile-App-Screens-Presentation-Mockup-PSD.jpg);
    background-size: cover;

    .auth-left-mobile-screenshot{
      width: 80%;
      height: 85%;
      background-image: url(../../assets/images/taxi-link-billing.png);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 5%;
      left: 20%;
    }
  }
}
