
// Booststrap functions and variables
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// Template variables
@import './variables';

// SCSS compass funtions
@import '~compass-mixins/lib/compass';
@import '~compass-mixins/lib/animate';

// Bootstrap main SCSS
@import '~bootstrap/scss/bootstrap.scss';

// Template mixins
@import './mixins/animation';
@import './mixins/badges';
@import './mixins/blockquote';
@import './mixins/breadcrumbs';
@import './mixins/buttons';
@import './mixins/cards';
@import './mixins/misc';
@import './mixins/no_ui_slider';
@import './mixins/pagination';
@import './mixins/popovers';
@import './mixins/tooltips';
@import './mixins/width';

// Core styles
@import './background';
@import './reset';
@import './functions'; 
@import './footer';
@import './misc';
@import './utilities';
@import './demo';
@import './typography';
@import './spinner';

@import './vertical-wrapper';
@import './navbar';
@import './sidebar';
@import './layouts';

// components
@import "./components/accordions";
@import "./components/badges";
@import "./components/bootstrap-alert";
@import "./components/breadcrumbs";
@import "./components/buttons";
@import "./components/cards";
@import "./components/carousel";
@import "./components/checkbox-radio";
@import "./components/dashboard";
@import "./components/datepicker";
@import "./components/dropdown";
@import "./components/forms";
@import "./components/icons";
@import "./components/input-group";
@import "./components/list-group";
@import "./components/modal";
@import "./components/nav";
@import "./components/pagination";
@import "./components/popover";
@import "./components/profile";
@import "./components/rating";
@import "./components/tables";
@import "./components/timeline";
@import "./components/chat";
@import "./components/faq";
@import "./components/auth";

// Email
@import "./components/email/inbox";
@import "./components/email/email-read";
@import "./components/email/email-compose";

// Plugin styles
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "~highlight.js/styles/googlecode.css";
// @import url("//at.alicdn.com/t/font_o5hd5vvqpoqiwwmi.css"); //(feather-font). github https://github.com/AT-UI/feather-font
@import "../assets/fonts/feather/style.css";
@import "~flag-icon-css/css/flag-icon.min.css";
@import "~@mdi/font/css/materialdesignicons.min.css";
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '~animate.css/animate.min.css';
@import '~@fullcalendar/core/main.min.css';
@import '~@fullcalendar/daygrid/main.min.css';
@import '~@fullcalendar/timegrid/main.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~simplemde/dist/simplemde.min.css';
@import "~metismenujs/scss/metismenujs";
@import '~dropzone/dist/min/dropzone.min.css';
@import '~quill/dist/quill.snow.css';
@import '~angular-archwizard/archwizard.css';
@import '~simple-datatables/dist/style.css';


// Plugin overrides
@import "./components/plugin-overrides/full-calendar";
@import "./components/plugin-overrides/ng-select";
@import "./components/plugin-overrides/ngx-chips";
@import "./components/plugin-overrides/ngx-quill";
@import "./components/plugin-overrides/perfect-scrollbar";
@import "./components/plugin-overrides/simplemde";
@import "./components/plugin-overrides/sweet-alert";
@import "./components/plugin-overrides/dropzone";
@import "./components/plugin-overrides/angular-archwizard";
@import "./components/plugin-overrides/apex-charts";
@import "./components/plugin-overrides/data-tables";

.modal-backdrop {
    background: #00000080;
    opacity: 1 !important;
}

.modal-fullscreen{
    width: 100vw !important;
    max-width: 100vw;
    height: 100%;
    margin: 0;

    .modal-content{
        min-height: 100%;
    }
}