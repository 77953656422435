.chat-wrapper {
  height: calc(100vh - #{$navbar-height} - 102px);
  @media(max-width: 991px) {
    min-height: 100%;
  }
  @media(max-width: 991px) {
    height: 100%;
  }
  .chat-aside {
    @media(min-width: 992px) {
      padding-right: 23px;
    }
    .aside-body {
      .nav-tabs {
        .nav-item {
          a {
            color: $body-color;
            -webkit-transition: color .2s ease-in-out;
            transition: color .2s ease-in-out;
            &.active {
              color: theme-color(primary);
            }
          }
        }
      }
      .tab-content {
        .tab-pane {
          .ps {
            position: relative;
            max-height: calc(100vh - 385px);
            @media(max-width: 767px) {
              min-height: calc(100vh - 360px);
            }
            @media (min-width: 768px) and (max-width: 991px) {
              min-height: calc(100vh - 388px);
            }
          }
          .chat-list {
            .chat-item {
              a {
                > div {
                  padding-top: 11px;
                  padding-bottom: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
  .chat-content {
    @media(max-width: 991px) {
      position: absolute;
      background: $card-bg;
      left: 0;
      bottom: -1px;
      top: 0;
      right: 0;
      display: none;
      &.show {
        display: block;
      }
    }
    .chat-header {
      padding: 0 10px;
    }
    .chat-body {
      position: relative;
      max-height: calc(100vh - 340px);
      @media(max-width: 767px) {
        max-height: calc(100vh - 315px);        
      }
      @media(max-width: 991px) {
        max-height: calc(100vh - 342px);        
      }
      margin-top: 20px;
      margin-bottom: 20px;
      .messages {
        padding: 0 10px;
        list-style-type: none;
        .message-item {
          @include display-flex;
          max-width: 80%;
          margin-bottom: 20px;
          @media(max-width: 767px) {
            max-width: 95%;
          }
          .content {
            .bubble {
              position: relative;
              padding: 7px 15px;
              margin-bottom: 4px;
              width: -webkit-fit-content;
              width: -moz-fit-content;
              width: fit-content;
            }
            span {
              font-size: 12px;
              color: $text-muted;
            }
          }
          &.friend {
            img {
              @include order(1);
              margin-right: 15px;
            }
            .content {
              @include order(2);
              .bubble {
                background: rgba(theme-color(primary), .1);
                border-radius: 0 5px 5px;
                &::before {
                  content: '';
                  width: 0;
                  height: 0;
                  position: absolute;
                  left: -10px;
                  top: 0;
                  border-top: 5px solid rgba(theme-color(primary), .1);
                  border-bottom: 5px solid transparent; 
                  border-left: 5px solid transparent; 
                  border-right:5px solid rgba(theme-color(primary), .1); 
                }
              }
            }
          }
          &.me {
            margin-left: auto;
            img {
              @include order(2);
              margin-left: 15px;
            }
            .content {
              @include order(1);
              margin-left: auto;
              .bubble {
                background: rgba(theme-color(info), .1);
                border-radius: 5px 0 5px 5px;
                margin-left: auto;
                &::before {
                  content: '';
                  width: 0;
                  height: 0;
                  position: absolute;
                  right: -10px;
                  top: 0;
                  border-top: 5px solid rgba(theme-color(info), .1);
                  border-bottom: 5px solid transparent; 
                  border-left: 5px solid rgba(theme-color(info), .1); 
                  border-right:5px solid transparent; 
                }
              }
              span {
                text-align: right;
                display: block;
              }
            }
          }
        }
      }
    }
    .chat-footer {
      button {
        width: 40px;
        height: 40px;
        i {
          font-size: 18px;
        }
      }
      .form-control {
        height: 40px;
        @include placeholder {
          padding-top: 3px;
        }
      }
    }
  }
  figure {
    position: relative;
    .status {
      width: 11px;
      height: 11px;
      background: theme-color(secondary);
      position: absolute;
      bottom: 0;
      right: -2px;
      border-radius: 50%;
      border: 2px solid $card-bg;
      &.online {
        background: theme-color(success);
      }
      &.offline {
        background: theme-color(secondary);
      }
    }
  }
}

.rtl {
  .chat-wrapper {
    .chat-content {
      .chat-body {
        .messages {
          .message-item {
            &.friend {
              img {
                margin-right: 0;
                margin-left: 15px;
              }
              .content {
                .bubble {
                  border-radius: 5px 0 5px 5px;
                  margin-right: 0;
                  margin-left: auto;
                  &::before {
                    left: auto;
                    right: -10px;
                    border-right: 5px solid transparent; 
                    border-left:5px solid rgba(theme-color(primary), .1); 
                  }
                }
              }
            }
            &.me {
              margin-left: 0;
              margin-right: auto;
              img {
                margin-left: 0;
                margin-right: 15px;
              }
              .content {
                .bubble {
                  border-radius: 0 5px 5px 5px;
                  margin-left: 0;
                  margin-right: auto;
                  &::before {
                    right: auto;
                    left: -10px;
                    border-right: 5px solid rgba(theme-color(info), .1); 
                    border-left:5px solid transparent; 
                  }
                }
                span {
                  text-align: left;
                };
              }
            }
          }
        }
      }
    }
  }
}